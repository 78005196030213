import { Paddle, PaddleEventData, initializePaddle } from '@paddle/paddle-js';
import mitt from 'mitt';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    paddleInstance: Promise<Paddle | undefined> | undefined;
  }
}

let paddleEvents = mitt<Record<'evt', PaddleEventData>>();

export let usePaddle = () => {
  let [paddle, setPaddle] = useState<Paddle>();

  useEffect(() => {
    if (!window.paddleInstance) {
      window.paddleInstance = initializePaddle({
        environment: import.meta.env.VITE_PADDLE_ENVIRONMENT,
        token: import.meta.env.VITE_PADDLE_KEY,

        eventCallback(event) {
          paddleEvents.emit('evt', event);
        }
      });
    }

    if (window.paddleInstance) {
      window.paddleInstance.then(setPaddle);
    }
  }, []);

  return {
    paddle,
    onEvent: (cb: (event: PaddleEventData) => void) => {
      paddleEvents.on('evt', cb);

      return () => {
        paddleEvents.off('evt', cb);
      };
    }
  };
};
