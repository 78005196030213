import { createLoader } from '@lb-lib/ui-data';
import { createCheckoutClient } from '@loopbound-ares/rpc-core/clients/checkout';

let checkoutClient = createCheckoutClient({
  endpoint: import.meta.env.VITE_CHECKOUT_ENDPOINT,
  client: {
    name: 'checkout',
    version: 'latest'
  }
});

export let checkoutLoader = createLoader({
  name: 'checkout',
  fetch: async (input: { transactionId: string }) => {
    return checkoutClient.checkout.getPaddleTransaction(input);
  },
  mutators: {}
});

export let useCheckout = (transactionId: string | null) => {
  return checkoutLoader.use(transactionId ? { transactionId } : null);
};
