import { Button, CenteredSpinner, Error, Spacer, Text, Title, theme } from '@lb-lib/ui';
import { CheckoutEventNames } from '@paddle/paddle-js';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { checkoutLoader, useCheckout } from '../client';
import { Checkout } from '../components/checkout';
import { usePaddle } from '../hooks/paddle';
import { usePreview } from '../hooks/usePreview';

let Wrapper = styled('div')`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
`;

let Side = styled('div')`
  min-height: 100%;
  overflow-y: auto;
`;

let CheckoutSide = styled(Side)`
  padding: 20px 30px;
`;

let TransactionInfo = styled(Side)`
  background: ${theme.colors.gray100};
  border-left: 1px solid ${theme.colors.gray300};
  padding: 20px 30px;
  display: flex;
  align-items: center;
`;

let TransactionInfoInner = styled('div')`
  width: 100%;
  max-width: 500px;
`;

let List = styled('ul')`
  list-style: none;
  padding: 0px;
`;

let ListItem = styled('li')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

let HR = styled('hr')`
  border: none;
  background: transparent;
  border-top: 1px solid ${theme.colors.gray400};
  margin: 15px 0px;
`;

let Center = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 30px 0px;
`;

export let CheckoutPage = () => {
  let [searchParams] = useSearchParams();
  let txnId =
    searchParams.get('txnId') ??
    searchParams.get('_ptxn') ??
    searchParams.get('transactionId');

  let checkout = useCheckout(txnId);
  let { preview, fetchPreview } = usePreview(txnId);
  let { onEvent } = usePaddle();

  let interval = checkout.data?.items[0].price.paddle.billingCycle?.interval ?? 'month';

  let [status, setStatus] = useState<'checkout' | 'wait' | 'completed'>('checkout');

  useEffect(
    () =>
      onEvent(event => {
        checkoutLoader.refetchAll();
        fetchPreview();

        if (event.name == CheckoutEventNames.CHECKOUT_COMPLETED) {
          setStatus('wait');

          setTimeout(() => setStatus('completed'), 5000);
        }
      }),
    [onEvent]
  );

  if (checkout.error)
    return (
      <Center>
        <Error>{checkout.error.data.message}</Error>
      </Center>
    );
  if (!txnId || checkout.isLoading || status == 'wait')
    return (
      <Center>
        <CenteredSpinner />
      </Center>
    );

  return (
    <>
      {status == 'completed' && (
        <Center>
          <div>
            <Title as="h1" weight="strong" size="4" align="center">
              Thank you!
            </Title>
            <Spacer height={10} />
            <Text as="p" weight="medium" size="2" align="center">
              Your purchase was successful. You will receive an email confirmation shortly.
            </Text>
            <Spacer height={20} />
            {window.parent && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  onClick={() => {
                    window.parent.postMessage({ type: 'checkout-completed' }, '*');
                    window.postMessage({ type: 'checkout-completed' }, '*');
                  }}
                >
                  Close
                </Button>
              </div>
            )}
          </div>
        </Center>
      )}

      {status == 'checkout' && (
        <>
          {checkout.data?.origin == 'subscription_payment_method_change' ? (
            <Center>
              <div style={{ maxWidth: 500, width: '100%' }}>
                <Title as="h1" weight="strong" size="5" align="center">
                  Update Payment Method
                </Title>
                <Spacer height={10} />
                <Text as="p" weight="medium" size="2" align="center">
                  Please enter your payment details to update your payment method.
                </Text>
                <Spacer height={20} />
                <Checkout transactionId={txnId} />
              </div>
            </Center>
          ) : (
            <Wrapper>
              <CheckoutSide>
                <Checkout transactionId={txnId} />
              </CheckoutSide>
              <TransactionInfo>
                <TransactionInfoInner>
                  {preview?.data ? (
                    <>
                      <List>
                        {preview.data.details.lineItems.map((item, i) => (
                          <ListItem key={i}>
                            <Text size="2" weight="bold">
                              {item.product?.name ?? ''}
                            </Text>
                            <Text size="2" weight="medium">
                              {item.quantity} x{' '}
                              {Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: preview.data.currencyCode
                              }).format(Number.parseInt(item.unitTotals.total) / 100)}{' '}
                              / {interval}
                            </Text>
                          </ListItem>
                        ))}

                        <HR />

                        <ListItem>
                          <Text size="2" weight="bold">
                            Subtotal
                          </Text>
                          <Text size="2" weight="medium">
                            {Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: preview.data.currencyCode
                            }).format(
                              Number.parseInt(preview.data.details.totals.subtotal) / 100
                            )}
                          </Text>
                        </ListItem>

                        <ListItem>
                          <Text size="2" weight="bold">
                            Tax{' '}
                            {preview.data.details.taxRatesUsed[0] &&
                              `(${
                                Number.parseFloat(
                                  preview.data.details.taxRatesUsed[0].taxRate
                                ) * 100
                              }%)`}
                          </Text>
                          <Text size="2" weight="medium">
                            {Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: preview.data.currencyCode
                            }).format(Number.parseInt(preview.data.details.totals.tax) / 100)}
                          </Text>
                        </ListItem>

                        <HR />

                        {preview.data.details.totals.discount &&
                          preview.data.details.totals.discount != '0' && (
                            <>
                              <ListItem>
                                <Text size="2" weight="bold">
                                  Discount
                                </Text>
                                <Text size="2" weight="medium">
                                  -
                                  {Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: preview.data.currencyCode
                                  }).format(
                                    Number.parseInt(preview.data.details.totals.discount) / 100
                                  )}
                                </Text>
                              </ListItem>

                              <HR />
                            </>
                          )}

                        {preview.data.details.totals.credit &&
                          preview.data.details.totals.credit != '0' && (
                            <>
                              <ListItem>
                                <Text size="2" weight="bold">
                                  Credit Applied
                                </Text>
                                <Text size="2" weight="medium">
                                  -
                                  {Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: preview.data.currencyCode
                                  }).format(
                                    Number.parseInt(preview.data.details.totals.credit) / 100
                                  )}
                                </Text>
                              </ListItem>

                              <HR />
                            </>
                          )}

                        <ListItem>
                          <Text size="2" weight="bold">
                            Due Today
                          </Text>
                          <Text size="2" weight="medium">
                            {Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: preview.data.currencyCode
                            }).format(
                              Number.parseInt(preview.data.details.totals.grandTotal) / 100
                            )}
                          </Text>
                        </ListItem>
                      </List>

                      <Text size="1" color="gray700">
                        Actual rates will be calculated based on your location and tax
                        settings. We will send a receipt to{' '}
                        <span>{checkout.data?.customer?.email ?? 'your email'}</span> with the
                        final amount.
                      </Text>
                    </>
                  ) : (
                    <CenteredSpinner />
                  )}
                </TransactionInfoInner>
              </TransactionInfo>
            </Wrapper>
          )}
        </>
      )}
    </>
  );
};
