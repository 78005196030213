import { TransactionPreviewResponse } from '@paddle/paddle-js';
import { useEffect, useRef, useState } from 'react';
import { useCheckout } from '../client';
import { usePaddle } from './paddle';

export let usePreview = (transactionId: string | null) => {
  let checkout = useCheckout(transactionId);
  let { paddle } = usePaddle();

  let [res, setRes] = useState<TransactionPreviewResponse>();

  let fetchPreviewRef = useRef<() => void>(() => {});
  let currentPreviewIndex = useRef(0);
  fetchPreviewRef.current = () => {
    if (!paddle || !checkout.data) return;

    let index = ++currentPreviewIndex.current;

    paddle
      .TransactionPreview({
        items: checkout.data.items.map(item => ({
          priceId: item.price.paddle.id,
          quantity: item.quantity
        })),
        customerId: checkout.data.customerId ?? undefined,
        addressId: checkout.data.addressId ?? undefined,
        businessId: checkout.data.businessId ?? undefined,
        discountId: checkout.data.discountId ?? undefined
      })
      .then(r => {
        if (index != currentPreviewIndex.current) return;
        setRes(r);
      });
  };

  useEffect(() => {
    if (!paddle || !checkout.data) return;

    fetchPreviewRef.current();

    let iv = setInterval(fetchPreviewRef.current, 5000);
    return () => clearInterval(iv);
  }, [paddle, checkout.data]);

  return {
    preview: res,
    fetchPreview: () => fetchPreviewRef.current()
  };
};
