import { createClient } from '@lb-lib/rpc/client.mjs';
import type { CheckoutClient } from '../src/api/billing/root';

export type Transaction = Awaited<
  ReturnType<CheckoutClient['checkout']['getPaddleTransaction']>
>;
export type { CheckoutClient };

export let createCheckoutClient = (opts: {
  endpoint: string;
  client: {
    name: string;
    version: string;
  };
}) => createClient<CheckoutClient>(opts);
