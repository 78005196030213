'use client';

import { useEffect, useId, useRef } from 'react';
import { usePaddle } from '../hooks/paddle';

export let Checkout = (props: { transactionId: string }) => {
  let wrapperRef = useRef<HTMLDivElement>(null);
  let id = useId();
  let { paddle } = usePaddle();

  useEffect(() => {
    if (!paddle || !wrapperRef.current) return;

    paddle.Checkout.open({
      transactionId: props.transactionId,

      settings: {
        showAddDiscounts: true,
        showAddTaxId: true,
        allowLogout: false,

        displayMode: 'inline',
        theme: 'light',
        frameTarget: id,
        frameStyle: 'position: relative; width: 100%'
      }
    });
  }, [paddle, wrapperRef.current, id]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        minHeight: '100%'
      }}
    >
      <div ref={wrapperRef} className={id} style={{ maxWidth: 500, width: '100%' }} />
    </div>
  );
};
