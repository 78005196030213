import { ErrorPage, NotFoundPage } from '@loopbound-ares/components';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { CheckoutPage } from './pages';

export let router = createBrowserRouter([
  {
    path: '/',
    element: <CheckoutPage />,
    errorElement: <ErrorPage />
  }
]);

export let Pages = () => <RouterProvider router={router} fallbackElement={<NotFoundPage />} />;
